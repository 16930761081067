.App {
	font-family: sans-serif;
	text-align: center;
	height: 100vh;
}

#root,
body {
	height: 100vh;
	width: 100%;
	margin: 0px;
	display: flex;
	font-family: 'IBM Plex Mono', monospace;
}

#pageWrap {
	flex-grow: 1;
	margin: 10px;
	background-color: silver;
	border-radius: 5px;
	overflow: hidden;
}

#canvasWrap {
	height: 100%;
	width: 100%;
}

#loading {
	flex-grow: 1;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

#loadingText {
	/* background-color: coral; */
	padding: 20px 60px;
	border-radius: 5px;
	overflow: hidden;
	border: solid 1px black;
	/* font-size: 30px; */
}
